import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Map } from 'react-map-gl/maplibre';
import StaticMap from 'react-map-gl/maplibre';
import DeckGL from '@deck.gl/react';
import { ScatterplotLayer, IconLayer, PolygonLayer } from '@deck.gl/layers';
import { CompositeLayer } from 'deck.gl';
import { CardNew } from "../../components/Card"

import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import { TileLayer } from '@deck.gl/geo-layers';
import { COORDINATE_SYSTEM } from '@deck.gl/core';
import type { Color, Layer, LayersList, OrthographicViewState } from '@deck.gl/core';
import { Deck, OrthographicView } from '@deck.gl/core';
import { CollisionFilterExtension, CollisionFilterExtensionProps, DataFilterExtension, DataFilterExtensionProps } from '@deck.gl/extensions';
// Load json file
// import icon from '../../../public/icon.json';
import { NonGeoBoundingBox, TileBoundingBox, TileLoadProps } from '@deck.gl/geo-layers/dist/tileset-2d/types';
import { PickingInfo } from 'deck.gl';

import { useAuth0 } from "@auth0/auth0-react";


import useApi from '../../components/useApi';

const iconURL = "/icon.png";
// const iconURL = icon.icon;
const MALE_COLOR: Color = [0, 128, 255];
const FEMALE_COLOR: Color = [255, 0, 128];


const INITIAL_VIEW_STATE: OrthographicViewState = {
    target: [0, 0, 0],
    zoom: 0
};

type DataPoint = [x: number, y: number, gender: number];
type UMAPDataPoint = { uuid: string, embedding: [x: number, y: number], imageUrl?: string, queryRank?: number };
type UMAPResult = { data: UMAPDataPoint[]};
// type UMAPResult = { data: UMAPDataPoint[], area: number, density: number };
type DataBounds = [[minX: number, minY: number], [maxX: number, maxY: number]];
type Visible = { scatter: boolean, icon: boolean };
const view = new OrthographicView();


function TooltipImage({ hoverObject, collectionId }: { hoverObject: UMAPDataPoint, collectionId: string }) {
    const restApi = useApi();
    const [ url, setUrl ] = useState<string | null>(null);
    const [ loading, setLoading ] = useState<boolean>(true);

    useEffect(() => {
        if(!hoverObject) return;

        const controller = new AbortController()
        const signal = controller.signal;
        const item = localStorage.getItem(hoverObject.uuid);
        if (item) {
            const { url, expiryDate } = JSON.parse(item);
            if (Date.now() < expiryDate) {
                setUrl(url);
                setLoading(false);
                return;
            }
        }

        setLoading(true);
        restApi.getTempUrls([hoverObject.uuid], collectionId, {signal})
        .then(res => res.json())
        .then(({urls}: { urls: string[] }) => {
            const item = {
                url: urls[0],
                expiryDate: Date.now() + 1000 * 60 * 60 * 24
            }
            localStorage.setItem(hoverObject.uuid, JSON.stringify(item));
            if(signal.aborted) return;
            setUrl(urls[0]);
        })
        .catch(err => {})
        .finally(() => {
            if(signal.aborted) return;
            setLoading(false);
        });

        return () => {
            controller.abort();
        }
    }, [hoverObject, collectionId]);
    
    // if(!hoverObject) return null;


    const tooltip =
        <div className="info-box" style={{width: "400px"}}>
            <div className="info-box-header" style={{width: "128px"}}>
                <div className="text-center" style={{ width: "128px"}}>
                    {(!loading && url) && 
                        <img className="p-1" src={url} style={{ width: "auto", height: "auto", maxWidth: "100%", maxHeight: "100%" }} />
                    }
                </div>
            </div>
            <div className="info-box-body mt-4 mb-4 ml-2">
                <p style={{fontSize: "12px"}}>{hoverObject.uuid}</p>
            </div>
        </div>

    return tooltip;
}

export default function UmapExplorer({
    // parentRef,
    pointData,
    collectionId = undefined,
    highlightedData = [],
    onImageSelect = () => {},
}: {
    // parentRef?: React.RefObject<HTMLDivElement>;
    pointData?: UMAPResult;
    collectionId?: string;
    highlightedData?: UMAPDataPoint[];
    onImageSelect?: (object: UMAPDataPoint) => void;
}) {
    const restApi = useApi();

    const [viewState, setViewState] = useState<OrthographicViewState>(INITIAL_VIEW_STATE);
    const [density, setDensity] = useState<number>(0);
    const [screenDensity, setScreenDensity] = useState<number>(0);
    const [area, setArea] = useState<number>(0);
    const [dataBounds, setDataBounds] = useState<DataBounds | null>(null);
    const [hoverState, setHoverState] = useState<{pointerX: number, pointerY: number, hoveredObject: UMAPDataPoint | null}>({ pointerX: 0, pointerY: 0, hoveredObject: null });
    const boundsRef = useRef<DataBounds | null>();
    const densityRef = useRef<number>();
    const screenDensityRef = useRef<number>();
    const areaRef = useRef<number>();
    const dataRef = useRef<UMAPResult>();

    boundsRef.current = dataBounds;
    densityRef.current = density;
    screenDensityRef.current = screenDensity;
    areaRef.current = area;
    dataRef.current = pointData;


    const layerVisibility = { scatter: viewState.zoom as number <= 9, icon: viewState.zoom as number > 9 };

    const deckRef = useRef<HTMLDivElement>(null);

    function getTileData(props: TileLoadProps) {
        let returnData: UMAPResult = { data: [] };
        const { index: { x, y, z }, bbox, signal } = props;
        const nbbox = bbox as NonGeoBoundingBox;
        const _data = dataRef.current?.data;
        
        if (!_data || !boundsRef.current) return returnData;

        const tileData = _data.filter(d => d.embedding[0] > nbbox.left && d.embedding[0] < nbbox.right && d.embedding[1] > nbbox.top && d.embedding[1] < nbbox.bottom);

        const gridSize = 3;
        const grid = new Array(gridSize * gridSize).fill(false);

        // Filter such that only one point is shown per grid cell
        const gridWidth = (nbbox.right - nbbox.left) / gridSize;
        const gridHeight = (nbbox.bottom - nbbox.top) / gridSize;

        returnData.data = tileData.filter(d => {
            const x = Math.floor((d.embedding[0] - nbbox.left) / gridWidth);
            const y = Math.floor((d.embedding[1] - nbbox.top) / gridHeight);
            const i = x + y * gridSize;
            if (!grid[i]) {
                grid[i] = true;
                return true;
            }
            return false;
        })

        return restApi.getTempUrls(returnData.data.map(d => d.uuid), collectionId)
            .then(res => res.json())
            .then(({urls}: { urls: string[] }) => {
                returnData.data.forEach((d, i) => {
                    d.imageUrl = urls[i];
                    const item = {
                        url: urls[i],
                        expiryDate: Date.now() + 1000 * 60 * 60 * 24
                    }
                    localStorage.setItem(d.uuid, JSON.stringify(item));
                });
                return returnData;
            });
    }

    function onViewStateChange(e: any) {
        if (!e.interactionState.inTransition) {
            setViewState(
                { ...viewState, ...e.viewState }
            );
        }
    }


    useEffect(() => {
        if (!pointData) return;
        const data = pointData.data;

        if (data.length === 0) return;

        const minX = Math.min(...data.map(d => d.embedding[0]));
        const maxX = Math.max(...data.map(d => d.embedding[0]));
        const minY = Math.min(...data.map(d => d.embedding[1]));
        const maxY = Math.max(...data.map(d => d.embedding[1]));
        const _dataBounds: DataBounds = [[minX, minY], [maxX, maxY]]
        setDataBounds(_dataBounds);

        const _area = (maxX - minX) * (maxY - minY);
        const _density = data.length / area;
        setArea(_area);
        setDensity(_density);

        // Calculate the center of the bounds
        const centerX = (minX + maxX) / 2;
        const centerY = (minY + maxY) / 2;

        // Calculate the zoom level (you may need to adjust the multiplier)
        const divHeight = deckRef.current?.clientHeight as number;
        const divWidth = deckRef.current?.clientWidth as number;

        const zoom = Math.min(
            Math.log2(divHeight / (maxX - minX)),
            Math.log2(divWidth / (maxY - minY))
        );
        // const zoom = Math.min(
        //     Math.log2(window.innerWidth / (maxX - minX)),
        //     Math.log2(window.innerHeight / (maxY - minY))
        // ) - 0.25;
        setViewState({ target: [centerX, centerY, 0], zoom: zoom });
    }, [pointData]);


    let colorRange: Color[] = [];
    colorRange = [
        [255, 255, 204],
        [199, 233, 180],
        [127, 205, 187],
        [65, 182, 196],
        [44, 127, 184],
        [37, 52, 148],
    ]
    

    colorRange = colorRange.reverse();
    const heatmap = new HeatmapLayer<UMAPDataPoint>({
        id: 'HeatmapLayer',
        data: pointData?.data,
        aggregation: 'SUM',
        getPosition: (d: UMAPDataPoint) => d.embedding,
        getWeight: 1,
        colorRange: colorRange,
        weightsTextureSize: 512,
        intensity: 1,
        radiusPixels: 15,
        // colorDomain: [0,1000],
        threshold: 0.25,
        // radiusPixels: 2 ** (zoom - 3),
    });

    const toolTipScatterLayer = new ScatterplotLayer<UMAPDataPoint, CollisionFilterExtensionProps>({
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        id: 'tooltip-plot',
        data: pointData?.data,
        opacity: 1,
        // radiusScale: radius,
        radiusUnits: 'pixels',
        radiusMinPixels: 1,
        radiusMaxPixels: 50,
        getPosition: d => [d.embedding[0], d.embedding[1], 0],
        getFillColor: [0, 0, 0, 0],
        getRadius: 40,
        pickable: true,
        visible: layerVisibility.scatter,
        
        // onHover: showTooltip,

        collisionEnabled: true,
        collisionTestProps: { radiusScale: 0.8 },
        extensions: [new CollisionFilterExtension()],
        collisionGroup: "tooltip",
    });


    const numRanks = highlightedData.length;
    const alphaHigh = 255;
    const alphaLow = 0;
    const alphaRange = alphaHigh - alphaLow;
    const resultLayer = new ScatterplotLayer<UMAPDataPoint>({
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        id: 'highlight-plot',
        data: highlightedData,
        opacity: 1,
        // radiusScale: radius,
        radiusUnits: 'pixels',
        radiusMinPixels: 3,
        radiusMaxPixels: 40,
        getPosition: d => [d.embedding[0], d.embedding[1], 0],
        getFillColor: d => {
            const r = d.queryRank as number < 5 ? 0 : 255
            const g = d.queryRank as number < 5 ? 255 : alphaHigh - (((d.queryRank as number) * alphaRange) / numRanks) + alphaLow;
            const b = 0;
            return [r, g, b, 255]
        },
        pickable: true,
        visible: true,
    });

    const tileLayer = new TileLayer({
        id: 'TileLayer',
        maxZoom: 19,
        minZoom: 10,
        tileSize: 512,
        refinementStrategy: "best-available",
        maxRequests: 1,
        getTileData,
        renderSubLayers: props => {
            const { x, y, z } = props.tile.index;
            const layerId = `IconLayer ${x} ${y} ${z}`;
            const iconLayer = new IconLayer({
                coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
                id: layerId,
                data: props.data.data,
                getIcon: d => ({
                        url: d.imageUrl,
                        width: 120,
                        height: 120
                    }),
                getSize: 120,
                getPosition: d => [d.embedding[0], d.embedding[1]],
                sizeUnits: 'pixels',
                pickable: true,
            });
            return iconLayer;
        },
    });

    const _layers = [heatmap, tileLayer, toolTipScatterLayer, resultLayer];
    const layers = _layers;

    const tooltip = <TooltipImage hoverObject={hoverState.hoveredObject as UMAPDataPoint} collectionId={collectionId as string} />
    // const tooltip = <TooltipImage hoverObject={{ uuid: "ae5e7675-21e6-4285-a60c-14fc08cfe94e"} as UMAPDataPoint} collectionId={collectionId as string} />

    const updatePickingInfo = useCallback((info: PickingInfo) => {
        const { x, y, object } = info;
        setHoverState({ pointerX: x, pointerY: y, hoveredObject: object as UMAPDataPoint });
    }, []);

    const onImageClick = useCallback((info: PickingInfo) => {
        const { object } = info;
        if (object) {
            onImageSelect(object as UMAPDataPoint);
        }
    }, [onImageSelect]);

    return (
            <div ref={deckRef} style={{height: "100%", width: "100%"}}>
                <DeckGL
                    views={view}
                    controller
                    viewState={viewState}
                    onViewStateChange={onViewStateChange}
                    layers={layers}
                    onHover={updatePickingInfo}
                    onClick={onImageClick}
                />
                {hoverState.hoveredObject && tooltip}
            </div>
    );

}