import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const Readme = () => {
    const nav = useNavigate();

    return (
        <div className="content-wrapper">
            {/* <div className="content-header"><h1>Read me!</h1></div> */}
            <div className="content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h4 className="card-title w-100">
                                        Read me!
                                    </h4>
                                </div>
                                <div className="card-body">
                                    This is a demo web app.<br />
                                    It does not reflect the complete functionality of the project.<br />
                                    Currently the registration for new users is turned off. <br />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h4 className="card-title w-100">
                                        Project capabilities
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <h3>Data Management</h3>
                                    <ul>
                                        <li>Automatic data import from AWS S3.</li>
                                        <li>Upload of data into datasets</li>
                                        <li>Simple and Fast creation of image embeddings</li>
                                        <li>Data Indexing in high dimensional vector space for fast similarity search</li>
                                    </ul>

                                    <h3>Collection Querying / Similarity Search</h3>
                                    <ul>
                                        <li>Query Collections with images from the database or by uploading a file</li>
                                        <li>Fast query times for similarity search</li>
                                        <li>Finds duplicates and similar images in a few milliseconds</li>
                                    </ul>

                                    <h3>Data visualization</h3>
                                    <ul>
                                        <li>Comprehensive exploration of image embeddings through an interactive visualization</li>
                                        <li>Find clusters of similar images</li>
                                        <li>Analyze the dataset interactively by navigating a 2D-Vector Space</li>
                                        <li>Query images and see where similar images are located in the 2D-Vector Space</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h4 className="card-title w-100">
                                        Demo Restrictions
                                    </h4>
                                </div>
                                <div className="card-body">

                                    <h3>What is possible?</h3>
                                    <ul>
                                        <li>Exploration of public Collection "Open Images"</li>
                                        <li>Query and Explore the collection by either uploading your own query images<br />
                                        or navigating through the interactive visualization</li>
                                    </ul>

                                    <h3>What is <b>not</b> possible?</h3>
                                    <ul>
                                        <li>User Registration disabled</li>
                                        <li>Dataset and Collection Creation disabled</li>
                                        <li>Import of new data disabled</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h4 className="card-title w-100">
                                        Tech Stack
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <h4>Frontend</h4>
                                    <ul>
                                        <li>React</li>
                                        <li>Typescript</li>
                                        <li>deck.gl for visualization</li>
                                        <li>Auth0 for Authentication</li>
                                    </ul>

                                    <h4>Backend - Web Application / Rest Server</h4>
                                    <ul>
                                        <li>NodeJS / ExpressJS</li>
                                        <li>MongoDB</li>
                                    </ul>

                                    <h4>Workflow Orchestration</h4>
                                    <ul>
                                        <li>Apache Airflow</li>
                                        <li>Python</li>
                                    </ul>

                                    <h4>Vector Embeddings and Data Analysis</h4>
                                    <ul>
                                        <li>Python</li>
                                        <li>Tensorflow, Image Classification Models, Embedding Models</li>
                                        <li>VGG16 Vector Embeddings</li>
                                        <li>Milvus Vector Database</li>
                                        <li>RabbitMQ - Message Queue</li>
                                        <li>T-SNE, UMAP</li>
                                    </ul>

                                    <h4>Cloud</h4>
                                    <ul>
                                        <li>AWS S3</li>
                                        <li>AWS Batch</li>
                                        <li>AWS EC2</li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Readme;
