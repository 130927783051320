import React, { useEffect, useState } from 'react';
import useApi from '../../useApi';
import { apiCallResponseFeedback } from '../../../helper/ErrorHandler';
import Toast from '../../../components/elements/Toast'
import Button from '../../elements/Button';


const QueryImageButton = ({ callback, collection_id, image, vector_id, return_embeddings = false, children }) => {
    const { queryCollectionByUpload, queryCollectionByVectorId } = useApi();

    const onClick = (event) => {
        event.preventDefault()
        if(image) {
            queryCollectionByUpload(collection_id, image, return_embeddings)
                .then(response => apiCallResponseFeedback(response))
                .then((result) => {
                    callback(result)
                })
                .catch((error) => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Query failed',
                        text: error.message
                    })
                    // handle the error
                });
        } else if(vector_id) {
            queryCollectionByVectorId(collection_id, vector_id, return_embeddings)
                .then(response => apiCallResponseFeedback(response))
                .then((result) => {
                    callback(result)
                })
                .catch((error) => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Query failed',
                        text: error.message
                    })
                    // handle the error
                });
        }
    }

    const text = children || 'Query';

    return (
        <Button onClick={onClick}>{text}</Button>
    );
}

export default QueryImageButton;