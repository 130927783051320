import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

const HowToUse = () => {
    const nav = useNavigate();

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <h1>How to use</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title w-100">
                                        Explore <Link to="/collections/6707fe8b2f22a01160d0d369">Open Images - V7 Dataset</Link>
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <h4>Interactive Visualization</h4>
                                    <p>
                                        Open the collection and explore the dataset in the interactive mode.<br />
                                        The visualization will show clusters of images based on the similarity of their features.<br />
                                        When zooming in, the individual images will be displayed.<br />
                                    </p>
                                    
                                    <p>
                                        You can also upload your own picture and click the query button below the image to find similar images in the dataset.<br />
                                        Results will be highlighted in the visualization.<br />
                                        The 5 most similar images will be highlighted green.<br />
                                        Up to 1000 images will be highlighted yellow to orange based on their similarity.<br />
                                    </p>

                                    <h4>List View</h4>
                                    <p>
                                        Alternatively you can switch to list view to see the query results in a grid.<br />
                                        You can also choose an image from the list and use it as a query image.<br />
                                        Just click "Select for Query" below the image, followed by the query button and the Results will be displayed.<br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default HowToUse;
