import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Card from "../../components/Card"
import useApi from '../../components/useApi';
// import DatasetContent from '../../components/DatasetContent';
import FileUpload from '../../components/Fileupload';
import S3ImportSelector from '../../components/datasets/S3ImportSelector';

const DatasetDetailView = () => {
    const { dataset_id } = useParams();
    const [dataset, setDataset] = useState({});
    const [contents, setContents] = useState([]);
    const [similarImages, setSimilarImages] = useState([]);
    const [queryImage, setQueryImage] = useState(null);
    const restApi = useApi();

    function changeQueryImage(image) {
        setQueryImage(image)
    }

    function showSimilarImages(images) {
        setSimilarImages(images)
    }

    function handleQueryResult(result) {
        showSimilarImages(result)
    }

    useEffect(() => {
        restApi.getDataset(dataset_id)
            .then(response => response.json())
            .then(response => {
                setDataset(response.dataset)
                setContents(response.content)
            })
            .catch(err => console.log(err))

    }, [dataset_id]);

    return (
        <div className="content-wrapper">
            <div className='content-header'>
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{dataset.name}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to=".." relative='path'>Datasets</Link></li>
                                <li className="breadcrumb-item active">{dataset._id}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                            <Card title={dataset.name}>
                                Nothing to see here
                            </Card>
                        </div>
                        <div className="col-lg-6">
                            <Card title="Import new data">
                                <S3ImportSelector datasetId={dataset_id} />
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DatasetDetailView;