import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './css/CollectionsList.css';
import { useAuth0 } from "@auth0/auth0-react";
import useApi from './useApi';
import Card from './Card';
import Toast from '../components/elements/Toast'
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { Button } from 'react-bootstrap';

const CollectionsList = ({showPublic = false}) => {
  const [collections, setCollections] = useState([]);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const restApi = useApi();

  useEffect(() => {
    if (showPublic) {
      restApi.getPublicCollections()
        .then(response => response.json())
        .then(response => {
          setCollections(response.collections)
        });
    } else {
      restApi.getCollections()
        .then(response => response.json())
        .then(response => {
          setCollections(response.collections)
        })
    }
    
  }, [isLoading]);

  const handleDelete = (collection_id) => {
    restApi.deleteCollection(collection_id)
    .then(response => response.json())
    .then(response => {
      if (response.status !== 'success') {
        throw new Error(response.message)
      }
      Toast.fire({
        icon: 'success',
        title: response.message,
        text: ""
      })
    })
    .catch(error => {
      Toast.fire({
        icon: 'error',
        title: 'Collection not deleted',
        text: error.message
      })
    })
  }

  return (
    <Card title="Collections" cardClasses="" cardBodyClasses="p-0">
      <table className="table table-striped projects">
        <thead>
          <tr>
            <th style={{width: '1%'}}>
              #
            </th>
            <th style={{width: '20%'}}>
              Collection Name
            </th>
            <th style={{ width: '10%' }}>
              No. Objects
            </th>
            <th style={{width: '39%'}}>
              Description
            </th>
            <th style={{ width: '30%' }}>
              {/* Actions */}
            </th>
          </tr>
        </thead>
        <tbody>
          {collections.map((collection) => (
            <tr>
              <td>
                #
              </td>
              <td>
                {collection.name}
                <br />
                <small>Created: {collection.created_at}</small>
              </td>
              <td>
                {collection.no_objects || 0}
              </td>
              <td>
                {collection.description || "Description not available"}
              </td>
              <td className="project-actions text-right">
                <Link className="btn btn-primary btn-sm" to={`/collections/${collection._id}`}>
                  <i className="fas fa-folder">
                  </i>
                  &nbsp; View
                </Link>
                &nbsp;
                {/* <Link className="btn btn-info btn-sm disabled" to={`/collections/${collection._id}/edit`}> */}
                <Link to={`/collections/${collection._id}/edit`}>
                  <Button variant="info" className='btn-sm' disabled={showPublic}>
                    <i className="fas fa-pencil-alt">
                    </i>
                    &nbsp; Edit
                  </Button>
                </Link>
                &nbsp;
                <Button className="btn btn-danger btn-sm" disabled onClick={() => handleDelete(collection._id)}>
                  <i className="fas fa-trash">
                  </i>
                  &nbsp; Delete
                </Button>

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default CollectionsList;