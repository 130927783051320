import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import JobCard from '../components/jobs/JobCard';
import {CardNew} from '../components/Card';
const Dashboard = () => {
    const nav = useNavigate();

    return (
        <div className="content-wrapper">
            <div className="content-header"><h1>Dashboard (WIP)</h1></div>
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="col-md-12">
                                <JobCard numProcessedObjects={1500000} numFoundObjects={1500000} jobSource={"S3"} jobStatus="completed" jobId="52fc"></JobCard>
                            </div>
                            <div className="col-md-12">
                                <JobCard numProcessedObjects={2550} numFoundObjects={11750} jobSource={"S3"} jobStatus="running" jobId="7ab3"></JobCard>
                            </div>
                            <div className="col-md-12">
                                <JobCard numProcessedObjects={0} numFoundObjects={0} jobSource={"S3"} jobStatus="queued" jobId="7ab4"></JobCard>
                            </div>
                            <div className="col-md-12">
                                <JobCard numProcessedObjects={2550} numFoundObjects={11750} jobSource={"S3"} jobStatus="queued" jobId="28f1"></JobCard>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <CardNew>
                                <CardNew.Header><CardNew.Title>Info</CardNew.Title></CardNew.Header>
                                <CardNew.Body>This dashboard is nonfunctional and only serves as a placeholder.</CardNew.Body>
                            </CardNew>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
