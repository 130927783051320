import React from 'react';
import Navigation from './components/Navigation';

import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Login from './Login'
import Auth0Login from './views/Auth0Login'
import CollectionsListView from './views/CollectionsListView'
import CollectionDetailView from './views/CollectionDetailView'
import CollectionCreateView from './views/CollectionCreateView'

import DatasetsListView from './views/datasets/list'
import DatasetsCreateView from './views/datasets/create'
import DatasetsDetailView from './views/datasets/detail'

import { useAuth0 } from '@auth0/auth0-react';
import Dashboard from './views/Dashboard';
import LogoutButton from "./components/elements/LogoutButton";
import LoginButton from "./components/elements/LoginButton";
import ProtectedRoute, {WaitForLoading} from "./components/auth/ProtectedRoute";
import Readme from './views/Readme';
import CollectionExploreView from './views/collections/explore';
import HowToUse from './views/HowToUse';

const Layout = () => {
    const { isAuthenticated } = useAuth0();
    return (
        <BrowserRouter>
            <nav className="main-header navbar navbar-expand navbar-white">
                <ul className="navbar-nav">
                </ul>
                <ul className="navbar-nav ml-auto">

                    <li className="nav-item">
                        {isAuthenticated &&
                        <LogoutButton />}
                    </li>
                    <li className="nav-item">
                        {!isAuthenticated &&
                        <LoginButton />}
                    </li>

                </ul>


            </nav>
            <Navigation />
                <Routes>
                    <Route element={<WaitForLoading />}>
                        <Route exact path="/" element={<Readme />} />
                        <Route exact path="/login" element={<Login />} />
                        {/* <Route exact path="/readme" element={<Readme />} /> */}
                        <Route exact path="/howto" element={<HowToUse />} />
                        <Route exact path="/dashboard" element={<Dashboard />} />
                        <Route element={<ProtectedRoute />}>
                            <Route exact path="/collections" element={<CollectionsListView />} />
                            <Route exact path="/collections/create" element={<CollectionCreateView />} />

                            <Route exact path="/datasets" element={<DatasetsListView/>} />
                            <Route exact path="/datasets/create" element={<DatasetsCreateView />} />
                        </Route>
                        {/* <Route path="/collections/:collection_id" element={<CollectionDetailView />} /> */}
                        <Route path="/collections/:collection_id" element={<CollectionExploreView />} />
                        <Route exact path="/datasets/:dataset_id" element={<DatasetsDetailView />} />
                        <Route exact path="/public/datasets" element={<DatasetsListView showPublic title="Public Datasets"/>} />
                        <Route exact path="/public/collections" element={<CollectionsListView showPublic title="Public Collections"/>} />
                        <Route exact path="/login/auth0" element={<Auth0Login />} />

                    </Route>
                </Routes>
        </BrowserRouter>
    );
};
  

export default Layout;