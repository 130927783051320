import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const FileSelector = (onChange = undefined) => {
    const [ selectedFile, setSelectedFile ] = useState(null);

    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
        if (onChange) {
            onChange(event.target.files[0]);
        }
    };

    return (
        <Form>
            <Form.Group controlId="formFile">
                <Form.Label className="btn btn-block btn-primary">Select</Form.Label>
                <Form.Control type="file" onChange={handleFileInputChange} style={{display: "none"}}/>
            </Form.Group>
        </Form>
    );
};

export default FileSelector;