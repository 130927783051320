import { Outlet, Navigate } from "react-router-dom";
import { React, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const ProtectedRoute = ({skipAuthenticationCheck = false}) => {
    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

    if(isLoading) {
        return <div></div>
    }
    if (isAuthenticated || skipAuthenticationCheck) {
        return <Outlet />;
    } else {
        // return <Outlet />;
        loginWithRedirect();
    }
};

const WaitForLoading = ({ skipAuthenticationCheck = false }) => {
    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

    if (isLoading) {
        return <div></div>
    }
    return <Outlet />
};

export default ProtectedRoute;
export {ProtectedRoute, WaitForLoading}