import React, { useEffect, useState } from "react";

const CachedImage = ({cacheKey, url, callback, children}) => {
    const [ image, setImage ] = useState(null);

    // useEffect(() => {
    //     let base64 = localStorage.getItem(cacheKey);
    //     if(base64) {
    //         callback(base64);
    //         setImage(base64);
    //     } else {
    //         fetch(url)
    //             .then(response => response.blob())
    //             .then(blob => {
    //                 var reader = new FileReader();
    //                 reader.readAsDataURL(blob)
    //                 reader.onload = () => {
    //                     const base64 = reader.result;
    //                     callback(base64);
    //                     setImage(base64);
    //                     localStorage.setItem(cacheKey, base64);
    //                 };
    //             });
    //     }
    // });

    return (
        <img src={url} style={{ maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto" }} className="rounded"></img>
        // <img src={image} style={{ maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto" }} className="rounded"></img>
    );
};

export default CachedImage;