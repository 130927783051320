import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const JobCard = ({ numProcessedObjects, numFoundObjects, jobSource, jobStatus, jobId }) => {
    const percentage = Math.floor(100 / numFoundObjects * numProcessedObjects)
    const percentageStyle = percentage + "%"

    var jobInfo = <div>
        <span className="info-box-text">Job ID: <b>{jobId}</b></span>
        <span className="info-box-text">Job Status: {jobStatus}</span>
    </div>
    if(jobStatus == "running" || jobStatus == "completed") {
        var progressbar =
            <div>
                <span className="info-box-number">{numProcessedObjects} / {numFoundObjects}</span>
                <div className="progress">
                    <div className="progress-bar" style={{ width: percentageStyle }}></div>
                </div>
            </div>
    } else {
        var progressbar = <div></div>
    }
    let boxClass = "info-box";
    if(jobStatus == "running") boxClass += " bg-info";
    if(jobStatus == "queued") boxClass += " bg-warning";
    if(jobStatus == "completed") boxClass += " bg-success";

    return (
        <div className={boxClass}>
            <span className="info-box-icon"><i className="fas fa-cogs"></i></span>
            <div className="info-box-content">
                {/* <span className="info-box-text">Job Source: {jobSource}</span> */}
                {jobInfo}
                {progressbar}
            </div>
        </div>
    )
}


export default JobCard;