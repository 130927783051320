import { FixedSizeList as List } from 'react-window';
import React, { useEffect, useState } from 'react';

import { Card, CardNew } from "../../../components/Card"
import CachedImage from '../../../components/elements/CachedImage';
import { Button } from 'react-bootstrap';


const QueryResultList = ({children}) => (
    <List
        height={150}
        itemCount={1000}
        itemSize={35}
        width={300}
    >
        {children}
    </List>
);

const createStandardResults = (images, setQueryImage) => {
    return images.map((image) => <StandardResult key={image.entity.uuid} image={image} setQueryImage={setQueryImage} />);
}

const StandardResult = ({image, setQueryImage}) => {
    const [ base64Image, setBase64Image ] = useState(null);
    const cachedImage = <CachedImage cacheKey={image.entity.uuid} url={image.url} callback={setBase64Image}></CachedImage>
    return (
        <div key={image.entity.uuid} className='col-lg-3'>
            <CardNew>
                <CardNew.Body>
                    <p>{image.distance}</p>
                    <div className="text-center" style={{ height: "224px" }}>
                        {cachedImage}
                    </div>
                    <div>
                        <Button className="btn-block mt-4 mb-4" onClick={() => setQueryImage(image.id, image.url)}>Select for Query</Button>
                    </div>
                </CardNew.Body>
            </CardNew>
        </div>
    );
}

const makeStandardResult = (image, setQueryImage) => {
    return (
        <div key={image.entity.uuid} className='col-lg-3'>
            <CardNew>
                <CardNew.Body>
                    <p>{image.distance}</p>
                    <div className="text-center">
                        <CachedImage cacheKey={image.entity.uuid} url={image.url} ></CachedImage>
                    </div>
                    <Button onClick={() => setQueryImage(image.id, image.url)}>Select for Query</Button>
                </CardNew.Body>
            </CardNew>
        </div>
    );
}

const QueryResultStandard = ({images, setQueryImage}) => {
    return (
        <div className="row">
            {createStandardResults(images, setQueryImage)}
        </div>
    )
}

const createPagedResults = (images, page, limit) => {
    const start = (page - 1) * limit;
    const end = Math.min(images.length, start + limit);
    return images.slice(start, end).map(createStandardResults);
}

const QueryResultPage = ({images, page, limit}) => {
    return (
        <div className="row">
            {createPagedResults(images)}
        </div>
    )
}

export { QueryResultList, QueryResultStandard, QueryResultPage };
export default QueryResultStandard;