import React from 'react';

const Card = ({ title, footer, cardTools, cardClasses = "", cardBodyClasses = "", children }) => {
    return (
        <div className={`card ${cardClasses}`}>
            <div className="card-header">
                <h3 className="card-title">{title}</h3>
                {/* {cardTools &&
                    <div className="card-tools">
                        <span className="badge badge-primary">{cardTools}</span>
                    </div>
                } */}
                {cardTools && {cardTools}}
            </div>
            <div className={`card-body ${cardBodyClasses}`} style={{whiteSpace: "pre-line"}}>
                {children}
            </div>
            {footer && <div className="card-footer">
                {footer}
            </div>}
        </div>
    );
}

// const CardBody = ({ children }) => {
//     return (
//         <div className="card-body" style={style}>
//             {children}
//         </div>
//     );
// }

const CardNew = ({ classNames = "", children, style={} }) => {
    return (
        <div className={`card ${classNames}`} style={style}>
            {children}
        </div>
    );
};




CardNew.Header = (props) => <div className="card-header">{props.children}</div>
CardNew.Title = (props) => <h3 className="card-title">{props.children}</h3>
CardNew.Body = ({ style = { whiteSpace: "pre-line" }, classNames = "", children }) => <div className={`card-body ${classNames}`} style={style}>{children}</div>
CardNew.CardTools = (props) => <div className="card-tools">{props.children}</div>
CardNew.BadgePrimary = (props) => <span className="badge badge-primary">{props.children}</span>
CardNew.Close = (props) => <button className="btn btn-tool" onClick={props.onClick}><i className="fas fa-times"></i></button>
CardNew.Trash = (props) => <button className="btn btn-tool" onClick={props.onClick}><i className="fas fa-trash "></i></button>

export default Card
export { CardNew, Card }