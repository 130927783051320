import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

const Auth0Login = () => {
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const code = searchParams.get("code");
        //const 
        // const uri = `http://localhost:3001/callback?code=${code}`;
        // const options = {
        //     method: 'GET',
        //     headers: { 'Content-Type': 'text/plain' },
        // };
    })

    return (
        <div>
            You are now logged in.
        </div>
    );
}

export default Auth0Login;
