import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Card, CardNew } from "../../components/Card"
import useApi from '../../components/useApi';
import { LocalStorageCache, useAuth0 } from '@auth0/auth0-react';
import Toast from '../../components/elements/Toast'
import FileSelector from '../../components/files/FileSelector';
import QueryImageButton from '../../components/collections/query/QueryImage';
import {QueryResultStandard} from '../../components/collections/query/QueryResults';
import Resizer from "react-image-file-resizer";
import { apiCallResponseFeedback } from '../../helper/ErrorHandler';
import UmapExplorer from '../../components/deckgl/UmapExplorer';
import { Button } from 'react-bootstrap';

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            224,
            224,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "file"
        );
    });


function encodeBase64(binary) {
    return new Promise(resolve => {
        let baseURL = "";
        let reader = new FileReader();

        reader.readAsDataURL(binary);
        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
}

const PageTabs = ({ page, maxPage, navigatePage }) => {
    const pages = [];
    
        if (maxPage <= 5) {
        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }
    } else {
        // Add pages around the current page
        for (let i = Math.max(1, page - 2); i <= Math.min(maxPage, page + 2); i++) {
            pages.push(i);
        }
    }
    
    return (
        <ul className="pagination pagination-sm mx-2 my-0 float-left">
            <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => navigatePage(1)}>First</button>
            </li>
            <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => navigatePage(page - 1)}>Previous</button>
            </li>
            {pages.map((p) => (
                ((p > 0) &&
                    <li key={p} className={`page-item ${(page === p) ? 'active' : ''}`}>
                        <button className="page-link" onClick={() => navigatePage(p)}>{p}</button>
                    </li>) ||
                ((p === -1) &&
                    <li key={p} className={`page-item disabled`}>
                        <button className="page-link">...</button>
                    </li>)
                
            ))}
            <li className={`page-item ${page === maxPage ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => navigatePage(page + 1)}>Next</button>
            </li>
            <li className={`page-item ${page === maxPage ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => navigatePage(maxPage)}>Last</button>
            </li>
        </ul>
    );
};


const CollectionExploreView = () => {
    const restApi = useApi();
    const { isAuthenticated } = useAuth0();

    const { collection_id } = useParams();
    const [ collection, setCollection ] = useState({});

    const [ queryImage, setQueryImage ] = useState(null);
    const [ queryImageId, setQueryImageId ] = useState(null);
    const [ base64Image, setBase64Image ] = useState(null);
    const [ resizedFile, setResizedFile ] = useState(null);
    const [ queryId, setQueryId ] = useState(null);
    const [ queryStats, setQueryStats ] = useState({});
    const [ queryResults, setQueryResults ] = useState([]);
    const [ visibleImages, setVisibleImages ] = useState([]);
    const [ page, setPage ] = useState(1);
    const [ limit, setLimit ] = useState(30);
    const [data, setData] = useState({data: []});
    const [highlightedData, setHighlightedData] = useState([])
    const [viewMode, setViewMode ] = useState("interactive");
    const { queryCollectionByUpload, queryCollectionByVectorId } = useApi();

    function queryByUpload(collection_id, image, return_embeddings) {
        queryCollectionByUpload(collection_id, image, return_embeddings)
            .then(response => apiCallResponseFeedback(response))
            .then((result) => {
                handleQueryResult(result)
            })
            .catch((error) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Query failed',
                    text: error.message
                })
                // handle the error
            });
    }

    function queryByVectorId(collection_id, vector_id, return_embeddings) {
        queryCollectionByVectorId(collection_id, vector_id, return_embeddings)
            .then(response => apiCallResponseFeedback(response))
            .then((result) => {
                handleQueryResult(result)
            })
            .catch((error) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Query failed',
                    text: error.message
                })
                // handle the error
            });
    }

    function query(return_embeddings) {
        if (queryImage) {
            queryByUpload(collection_id, resizedFile, false);
        } else if (queryImageId) {
            queryByVectorId(collection_id, queryImageId, false);
        }
    }

    function fileSelectorCallback(image) {
        setQueryImage(image);
        setQueryImageId(null);
        encodeBase64(image).then(base64 => {
            setBase64Image(base64);
        });
        resizeFile(image).then(resized => {
            setResizedFile(resized);
        });
    }

    function querySelectorCallback(imageId, imageUrl) {
        setQueryImage(null);
        setResizedFile(null);
        setBase64Image(imageUrl);
        setQueryImageId(imageId);
    }

    function getMaxPage() {
        return Math.ceil(queryResults.length / limit);
    }

    useEffect(() => {
        displayImages();
    }, [queryResults, page]);

    function navigatePage(currentPage) {
        setPage(currentPage);
    }

    function displayImages() {
        let images = queryResults;
        images = images.slice((page - 1) * limit, page * limit);
        let uuids = images.map(image => image.entity.uuid);

        let fetch_uuids = uuids.filter(uuid => localStorage.getItem(uuid) === null);
        if (fetch_uuids.length === 0) {
            setVisibleImages(images);
            return;
        }
        restApi.getTempUrls(fetch_uuids, collection_id)
            .then((response) => response.json())
            .then((tempURIs) => {
                //Extremely dirty, but this will do for now.
                //We only fetch uris for images that are not in local storage
                let offset = 0;
                for (let i = 0; i < tempURIs.urls.length; i++) {
                    let url = tempURIs.urls[i];
                    if (url == null) {
                        tempURIs.urls[i] = "https://via.placeholder.com/150"
                    }
                    while(images[i + offset].entity.uuid !== fetch_uuids[i]) {
                        offset++;
                    }
                    images[i + offset].url = tempURIs.urls[i]

                    const item = {
                        url: tempURIs.urls[i],
                        expiryDate: Date.now() + 1000 * 60 * 60 * 24
                    }
                    localStorage.setItem(fetch_uuids[i], JSON.stringify(item));
                }
                return images;
            }).then((images) => {
                setVisibleImages(images);
            })
    }

    function handleQueryResult(result) {
        localStorage.clear();
        setQueryId(result.query_id)
        setPage(1);
        setQueryResults(result.neighbors)
    }

    function clearQueryResults() {
        localStorage.clear();
        setQueryResults([])
        setPage(1)
        setQueryId(null)
    }

    function clearQueryImage() {
        setQueryImage(null)
    }


    useEffect(() => {
        restApi.getCollection(collection_id)
            .then(response => response.json())
            .then(response => {
                setCollection(response.collection);
                return response.collection;
            })
            .then((collection) => {
                return restApi.getQueryStats(collection.uuid)
            })
            .then(response => response.json())
            .then(({stats}) => {
                setQueryStats(stats);
            })
            .catch((err) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Error fetching collection',
                    text: err.message
                });
            });
    }, [collection_id, isAuthenticated]);

    
    
    useEffect(() => {
        fetch("/umap/open-images.json")
            .then(res => res.json())
            .then(d => {
                // Sorting for some reason destroys the visualization.
                // d.data.sort((a: UMAPDataPoint, b: UMAPDataPoint) => a.embedding[0] - b.embedding[0]);
                setData(d);
            })
    }, []);

    useEffect(() => {
        const highlights = new Map(queryResults.map((d, i) => [d.entity.uuid, i]));
        let _highlightedData = [];

        if (!(!data?.data || !highlights)) {
            for (let i = 0; i < data.data.length; i++) {
                if (highlights.has(data.data[i].uuid)) {
                    const d = data.data[i];
                    d.queryRank = highlights.get(data.data[i].uuid);
                    _highlightedData.push(d);
                }
            }
        }
        setHighlightedData(_highlightedData);
    }, [data, queryResults]);

    // This function is called when an image is selected in the UmapExplorer
    // It is currently deactivated, because the vector_ids are not present in the umap.json
    // We would need to fetch the vector_ids for the images in the umap.json or provide them in the umap.json
    const selectFromImageExplorer = useCallback(({vector_id, uuid}) => {
        const item = localStorage.getItem(uuid);
        try {
            const {url, expiryDate} = JSON.parse(item);
            if (expiryDate > Date.now()) {
                setQueryImageId(uuid);
                setBase64Image(url);
                setQueryImage(null);
                setResizedFile(null);
            } else {
                throw new Error("URL Expired")
            }
        } catch (e) {
            restApi.getTempUrls([uuid], collection_id)
                .then((response) => response.json())
                .then((tempURIs) => {
                    let url = tempURIs.urls[0];
                    if (url == null) {
                        url = "https://via.placeholder.com/150"
                    }
                    return url;
                }).then((url) => {
                    setQueryImage(null);
                    setResizedFile(null);
                    setQueryImageId(uuid);
                    setBase64Image(url);
                })
        }

    }, [collection_id]);

    const fileSelector = FileSelector(fileSelectorCallback);


    return (
        <div className="content-wrapper">
            <div className='content-header'>
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"><b>Explore Collection</b></h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                {!collection.public &&
                                    <li className="breadcrumb-item"><Link to=".." relative='path'>Collections</Link></li>
                                }
                                {collection.public &&
                                    <li className="breadcrumb-item"><Link to="/public/collections" relative='path'>Collections</Link></li>
                                }
                                <li className="breadcrumb-item active">{collection._id}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                            <Card title={collection.name}>
                                {collection.description}
                            </Card>
                        </div>
                        <div className="col-lg-6">
                            <Card title="Statistics - More coming soon">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h4>Queries within last 30 minutes</h4>
                                        <p>{queryStats?.queryCount}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <h4>Number of images</h4>
                                        <p>{collection.numDocuments}</p>
                                    </div>
                                    {/* <div className="col-md-4">
                      <h4>More Statistics</h4>
                      <p>{contents.length}</p>
                    </div> */}
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Card title="Upload an image">
                                        <div>
                                            <p className='p-2'>
                                                Upload an image to query the collection. <br />
                                                Similar images will be returned and displayed below.
                                            </p>
                                            {fileSelector}
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-lg-12">
                                    <Card title="Query Image">
                                        <div>
                                            <p className='p-2'>
                                                Query image will be displayed here.
                                            </p>
                                            {base64Image &&
                                                <div>
                                                    <div className="text-center">
                                                        <img className='rounded mb-4' style={{maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto"}} src={base64Image}></img>
                                                    </div>
                                                    <div>
                                                        {queryImageId &&
                                                            <QueryImageButton collection_id={collection.uuid} vector_id={queryImageId} callback={handleQueryResult}>Query</QueryImageButton>
                                                        }
                                                        {queryImage &&
                                                            <QueryImageButton collection_id={collection.uuid} image={resizedFile} callback={handleQueryResult} />
                                                        }
                                                        {/* <button className="btn btn-block btn-primary" onClick={triggerQuery}>Query</button> */}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-lg-12">
                                    <CardNew>
                                        <CardNew.Header>
                                            <CardNew.Title>How to use</CardNew.Title>
                                        </CardNew.Header>
                                        <CardNew.Body>
                                            <h4>Explore the collection</h4>
                                            <p>
                                                You can explore the collection in Interactive Mode by zooming and panning.<br />
                                                You can zoom in on clusters to see grouped images that are pairwise similar.<br />
                                            </p>
                                            <p>
                                                Zooming in on a cluster will display the underlying images.
                                            </p>
                                            <h4>Query collection</h4>
                                            <p>
                                                To query the collection, you can upload an image and click the query button.<br />
                                                The most similar images will be displayed in the List View which you can select<br />
                                                They also appear in the Interactive Explorer. The top 5 images will marked green. All other images will have a color coding from yellow to red, where red is the least similar.

                                            </p>
                                            <p></p>
                                        </CardNew.Body>
                                    </CardNew>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            {viewMode == "list" &&
                                    <CardNew>
                                        <CardNew.Header>
                                            <CardNew.Title>List View</CardNew.Title>
                                            {getMaxPage() > 0 &&
                                                <PageTabs className="ml-4" page={page} maxPage={getMaxPage()} navigatePage={navigatePage} />
                                            }
                                            <CardNew.CardTools>
                                                <Button className="mr-4" onClick={() => setViewMode('interactive')}>Change to interactive Mode</Button>
                                                <CardNew.BadgePrimary>Showing {queryResults.length} Results</CardNew.BadgePrimary>
                                                <CardNew.Trash onClick={clearQueryResults} />

                                            </CardNew.CardTools>
                                        </CardNew.Header>
                                        <CardNew.Body>

                                            {!queryId && <p>Perform a query to see the most similar images</p>}
                                            {queryId &&
                                                <QueryResultStandard images={visibleImages} setQueryImage={querySelectorCallback} />
                                            }
                                        </CardNew.Body>
                                    </CardNew>
                            }
                            {(viewMode == "interactive") && 
                                    <CardNew>
                                        <CardNew.Header>
                                        <CardNew.Title>Interactive Explorer - Zoom in to view images</CardNew.Title>
                                            <CardNew.CardTools>
                                                <Button className="mr-4" onClick={() => setViewMode('list')}>Change to List View</Button>
                                                <CardNew.BadgePrimary>Showing {queryResults.length} Results</CardNew.BadgePrimary>
                                                <CardNew.Trash onClick={clearQueryResults} />
                                            </CardNew.CardTools>
                                        </CardNew.Header>
                                        
                                        <div style={{ height: '50vh', width: '100%', position: 'relative' }}>
                                        <UmapExplorer pointData={data} highlightedData={highlightedData} collectionId={collection.uuid} />
                                        {/* <UmapExplorer pointData={data} highlightedData={highlightedData} onImageSelect={selectFromImageExplorer}/> */}
                                        </div>
                                    </CardNew>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollectionExploreView;